import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import addScreenshotsSchema from './addScreenshotsSchema';
import axios from "../../../../libs/axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import ScreenShotField from './ScreenShotField';
import toast from 'react-hot-toast';
import UploadFile from '../../../UploadFile';
import gallery_img from '../../../../assets/icons/addScreenshots/gallery_img.svg'

const AddScreenshots = ({ social, stage }) => {
    const {
        watch,
        handleSubmit,
        setValue,
        reset,
        control,
        formState: { errors },
    } = useForm({ resolver: yupResolver(addScreenshotsSchema), defaultValues: { screens: [] } });
  

    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "screens",
    });

    const removeScreen = (i) => {
        axios.post("/file/remove", {
            file: watch(`screens[${i}].image`),
        })
            .then((res) => {
                setValue(`screens[${i}].image`, "")
                remove(i);
            })
            .catch((err) => {
                fields.length > 1 && remove(i)
            })
    }

    const onSubmit = async (value) => {
        if(value.screens.some((screen) => !screen.views)) return setError("გთხოვთ შეავსოთ რიჩების რაოდენობა")
        setError("");
        axios.post('/screen', value)
            .then(() => {
                toast.success('სქრინები წარმატებით დაემატა');
                reset();
                window.location.href = "/profile#"
            })
            .catch((err) => {
                toast.error('დაფიქსირდა შეცდომა ' + err.response?.data?.message);
        })
    };


    return (
        <div className='flex min-h-[700px] box-border items-center w-full'>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={100}
                    allowTouchMove={false}
                    onSlideChange={() => setDisabled(false)}
                    onSlideChangeTransitionStart={() => setDisabled(true)}
                    onSlideChangeTransitionEnd={() => setDisabled(false)}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper !px-[10%] !py-[10%]"
                >
                    <SwiperSlide>
                        <UploadFile isMultiple={true} dir={'screens'} className='bg-gray-300 rounded-md flex justify-center items-center h-[400px]' setState={(val) => {
                                        val.map((value) => append({ views: null, image: value, social: social, stage: stage }))
                                    }}>
                                        <img className='!w-12 object-cover !h-12 animate-pulse' src={gallery_img} alt='img'></img>
                        </UploadFile>
                        <div className='divider'></div>
                        <div className="w-full mt-2 px-3 py-2 border border-gray-300 rounded-md text-md text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500">
                            გადადით შემდეგ გვერდზე
                        </div>
                    </SwiperSlide>
                    {fields.map((field, i) => (
                        <SwiperSlide key={i}>
                            <ScreenShotField disabled={disabled} social={social} stage={stage} append={append} removeScreen={removeScreen} errors={errors} control={control} setValue={setValue} watch={watch} i={i} field={field} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <h2 className='text-center text-red-600'>{error}</h2>
                <div className='flex gap-5 mt-5'>
                    <button className='py-2 w-full bg-blue-500 text-white rounded-lg' type='submit'>შენახვა</button>
                    <UploadFile isMultiple={true} dir={'screens'} className='self-end py-2 w-full text-center text-white h-full rounded-lg bg-blue-500' setState={(val) => {
                                        val.map((value) => append({ views: null, image: value, social: social, stage: stage }))
                                    }}>
                                        სქრინის დამატება
                    </UploadFile>
                    
                </div>
            </form>
        </div>
    )
    // return (
    //     <div className='flex items-center p-5'>
    //         <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
    //             <div className='w-full h-[400px] relative'>
    //                 <UploadFile dir={'screens'} className='h-full bg-gray-300 rounded-md' setState={(val) => setValue('image', val)}>
    //                     {watch('image') ?
    //                         <img className='w-full h-full' src={`http://localhost:5100${watch('image')}`} alt='...' />
    //                         :
    //                         <span></span>
    //                     }
    //                 </UploadFile>
    //                 <span className='z-10 bg-white absolute p-2 right-2 top-2 cursor-pointer rounded-md' onClick={(e) => removeFile({ url: watch('image'), reset: () => setValue('image', ""), e: e })}>წაშლა</span>
    //             </div>
    //             {errors.image && <p className='text-red-600 mt-2'>{errors.image.message}</p>}
    //             <div className='divider'></div>
    //             <Controller
    //                 name='views'
    //                 control={control}
    //                 rules={{ required: true }}
    //                 render={({ field }) => (
    //                     <Input
    //                         {...field}
    //                         type={'number'}
    //                         label="რიჩები"
    //                         min='1'
    //                         aria-invalid={errors.views ? "true" : "false"}
    //                     />
    //                 )}
    //             />
    //             {errors.views && <p className='text-red-600 mt-2'>{errors.views.message}</p>}
    //             <div className='divider'></div>
    //             <button className='p-5 bg-blue-500 text-white rounded-lg' type='submit'>ატვირთვა</button>
    //         </form>
    //     </div>
    // );
};

export default AddScreenshots;
