import React, { useState } from 'react';
import axios from "../../../../libs/axios";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ScreenShotField from './ScreenShotField';
import toast from 'react-hot-toast';
import UploadFile from '../../../UploadFile';
import gallery_img from '../../../../assets/icons/addScreenshots/gallery_img.svg'

const AddScreenshots = ({ social, stage }) => {
    const [error, setError] = useState("");

    const [screens, setScreens] = useState([]);
    const [index, setIndex] = useState(-1);
    
    const removeScreen = (i, image) => {
        axios.post("/file/remove", {
            file: image,
        })
            .then((res) => {
                setScreens(prev => prev.filter((_, idx) => idx !== i))
                setIndex(-1)
            })
            .catch((err) => {
                setIndex(-1)
                toast.error("დაფიქსირდა შეცდომა")
            })
    }

    const onSubmit = async () => {
        if(screens.some((screen) => !screen.views)) return setError("გთხოვთ შეავსოთ რიჩების რაოდენობა")
        axios.post('/screen', { screens: screens })
            .then(() => {
                toast.success('სქრინები წარმატებით დაემატა');
                setScreens([])
                setError("");
                setIndex(-1);
                window.location.href = "/profile#"
            })
            .catch((err) => {
                toast.error('დაფიქსირდა შეცდომა');
        })
    };

    return (
        <div className='flex min-h-[700px] box-border items-center w-full'>
            <div className='w-full'>
                <div className='flex'>
                    <button type='button' disabled={index === -1} className='disabled:opacity-50' onClick={() => setIndex(prev => prev <= -1 ? -1 : prev - 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-12 h-12' viewBox="0 0 320 512">
                                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                            </svg>
                    </button>
                    <div className="w-full">
                        
                        <div className={index === -1 ? "block" : "hidden"}>
                            <UploadFile isMultiple={true} dir={'screens'} className='bg-gray-300 rounded-md flex justify-center items-center h-[400px]' setState={(val) => {
                                            val.map((value) => setScreens((prev) => [...prev, { views: null, image: value, social: social, stage: stage }]))
                                        }}>
                                            <img className='!w-12 object-cover !h-12 animate-pulse' src={gallery_img} alt='img'></img>
                            </UploadFile>
                            <div className='divider'></div>
                            <div className="w-full mt-2 px-3 py-2 border border-gray-300 rounded-md text-md text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500">
                                გადადით შემდეგ გვერდზე
                            </div>
                        </div>
                        {screens.map((field, i) => (
                            <div key={i} className={`${index === i ? "block" : "hidden"}`}>
                                <ScreenShotField setError={setError} setScreens={setScreens} social={social} stage={stage} removeScreen={removeScreen} i={i} field={field} />
                            </div>
                        ))}
                    </div>
                    <button type='button' disabled={screens.length - 1 === index} className='disabled:opacity-50' onClick={() => setIndex(prev => prev >= screens.length - 1 ? screens.length - 1 : prev + 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-12 h-12' viewBox="0 0 320 512">
                                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                            </svg>
                    </button>
                </div>
                <h2 className='text-center text-red-600'>{error}</h2>
                <div className='flex gap-5 mt-5'>
                    <button className='py-2 w-full bg-blue-500 text-white rounded-lg' onClick={() => onSubmit()}>შენახვა</button>
                    <UploadFile isMultiple={true} dir={'screens'} className='self-end py-2 w-full text-center text-white h-full rounded-lg bg-blue-500' setState={(val) => {
                                        val.map((value) => setScreens((prev) => [...prev, { views: null, image: value, social: social, stage: stage }]))
                                    }}>
                                        სქრინის დამატება
                    </UploadFile>
                    
                </div>
            </div>
        </div>
    )
};

export default AddScreenshots;
