import * as yup from 'yup';

const addScreenshotsSchema = yup.object().shape({
    screens: yup.array().of(
        yup.object().shape({
            // views: yup.number().required('* რიჩების რაოდენობა აუცილებელია'),
            image: yup.string().required('* სურათი აუცილებელია')
        })
    )
});

export default addScreenshotsSchema;
