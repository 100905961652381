import React from 'react'
import { Link } from 'react-router-dom'
import fbIcon from "../../assets/socialsIcons/fb.svg"
import igIcon from "../../assets/socialsIcons/ig.svg"
import tkIcon from "../../assets/socialsIcons/tk.svg"
import ytIcon from "../../assets/socialsIcons/yt.svg"
import crown from "../../assets/icons/userCard/crown-solid.svg"

const UserCard = ({ name, lastname, social, avatar_url, fb, tk, ig, yt, isWinner, place, isNew }) => {
    
    const optimizedAvatarUrl = (imageUrl, width) => {
        return `${imageUrl}?width=${width}`;
    };

    return (
        <div className="card card-compact bg-base-100 shadow-xl">
            <div className="card-body">
                <div className="avatar">
                    <div className="w-full rounded-xl relative">
                        {isWinner &&
                            <div className='bg-white w-[50px] h-[50px] rounded-full absolute left-2 top-2 flex justify-center items-center'>
                                <div className='relative flex items-center justify-center w-[30px] h-[30px]'>
                                    <img src={crown} alt="crown" />
                                    <span className='text-base font-bold mt-2 absolute'>{place && place}</span>
                                </div>
                            </div>}
                        <img
                            className="rounded-xl avatarImage"
                            fill
                            src={optimizedAvatarUrl(avatar_url, 800)}
                            alt={`${name} ${lastname}`}
                            loading="lazy"
                            width={500}
                            height={500}
                            srcSet={`
                                ${optimizedAvatarUrl(avatar_url, 800)} 800w,
                                ${optimizedAvatarUrl(avatar_url, 400)} 400w,
                                ${optimizedAvatarUrl(avatar_url, 200)} 200w
                            `}
                            sizes="(max-width: 600px) 200px, (max-width: 1200px) 400px, 800px"
                        />
                    </div>
                </div>
                <h2 className="text-center card-title justify-center mt-3">{name} {lastname}</h2>
                <div className="divider m-0"></div>
                <div className="card-actions justify-center">
                    <div className="grid grid-flow-col gap-4">
                        {!isNew ?
                            <>
                                {fb && (
                                    <Link to={fb} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img src={fbIcon} alt="facebook" />
                                    </Link>
                                )}
                                {tk && (
                                    <Link to={tk} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img src={tkIcon} alt="tiktok" />
                                    </Link>
                                )}
                                {ig && (
                                    <Link to={ig} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img width={26} height={26} src={igIcon} alt="instagram" />
                                    </Link>
                                )}
                                {yt && (
                                    <Link to={yt} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img src={ytIcon} alt="youtube" />
                                    </Link>
                                )}
                            </>

                            :
                            <>
                                {social?.fb && (
                                    <Link to={social?.fb} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img src={fbIcon} alt="facebook" />
                                    </Link>
                                )}
                                {social?.tk && (
                                    <Link to={social?.tk} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img src={tkIcon} alt="tiktok" />
                                    </Link>
                                )}
                                {social?.ig && (
                                    <Link to={social?.ig} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img width={26} height={26} src={igIcon} alt="instagram" />
                                    </Link>
                                )}
                                {social?.yt && (
                                    <Link to={social?.yt} target="_blank" className="m-auto hover:scale-110 transition-all" rel='noreferrer'>
                                        <img src={ytIcon} alt="youtube" />
                                    </Link>
                                )}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCard