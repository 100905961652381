import React, { useEffect } from 'react'
import UploadVideo from './vid/UploadVideo'
import { useDispatch, useSelector } from 'react-redux';
import { fetchContentVideo } from '../../../redux/thunks/content';
import { addVideo } from '../../../redux/slices/content';


const MainPage = () => {
    const dispatch = useDispatch();
    const videoUrl = useSelector((state) => state.content);
    const isLoading = videoUrl.status !== 'loaded';
    
    useEffect(() => {
        dispatch(fetchContentVideo())
    }, [dispatch])
    

    const handleVideoUpload = (newVideoUrl) => {
        dispatch(addVideo(newVideoUrl))
        window.location.reload()
    }

    return (
        <div className='flex flex-col px-2 sm:px-10 gap-5'>
            <h1 className='text-3xl text-center mt-4'>მთავარი გვერდი</h1>
            <div className='card p-5 shadow-xl w-full bg-white h-fit box-border'>
                <h1 className='text-2xl ml-4 mt-2'>ვიდეო-რგოლის რედაქტირება:</h1>
                <div className='divider' />
                <UploadVideo onUpload={handleVideoUpload}/>
                <div className='relative w-full h-[30dvh] sm:h-[60dvh] py-4 mt-5 box-border'>
                    <div className="absolute left-0 top-0 video-docker w-full h-full overflow-hidden">
                        {(!isLoading || videoUrl.video) && (
                            <video
                                className="min-w-full min-h-full object-cover"
                                src={process.env.REACT_APP_EXPRESS_URL + videoUrl.video}
                                muted
                                loop
                                autoPlay
                            >
                            </video>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainPage
