import { useState, useEffect } from 'react';
import { Tab, TabList, TabPanel, Tabs as Tb } from 'react-tabs';
import SocialReachesSingleItem from './SocialReachesSingleItem';
import axios from '../../../../libs/axios'
import AddScreenshots from './AddScreenshots';
import useCurrentUser from "../../../../hooks/useCurrentUser"

const SocialReachesSingle = ({ social, title, hasReached }) => {
    const [screens, setScreens] = useState([]);
    const [activeStage, setActiveStage] = useState(1);
    const { user } = useCurrentUser();

    useEffect(() => {
        axios.get(`/screen?stage=${activeStage}&social=${social}`)
            .then((res) => {
                setScreens(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [social, activeStage]);

    return (
        <>
            <div className="card bg-base-100 shadow-xl">
                <div className="card-body">
                    <h2 className="card-title">{title}</h2>
                    
                    <Tb selectedTabClassName='tab-active'>
                        <TabList>
                            <Tab onClick={() => setActiveStage(1)} className={`tab tab-lg tab-lifted font-fira-go rounded ${activeStage === 1 && "bg-gray-200"}`}>ეტაპი 1</Tab>
                            {hasReached && <Tab onClick={() => setActiveStage(2)} className={`tab tab-lg tab-lifted font-fira-go rounded ${activeStage === 2 && "bg-gray-200"}`}>ეტაპი 2</Tab>}
                        </TabList>
                        <TabPanel>
                            <div className='flex flex-wrap gap-8'>
                                {screens.length > 0 ? (
                                    screens.filter(item => item.stage === 1).map((item, i) => (
                                        <SocialReachesSingleItem
                                            key={i}
                                            {...item}
                                        />
                                    ))
                                ) : (
                                    <p>სქრინები არ არის ატვირთული</p>
                                )}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='flex flex-wrap gap-8'>
                                {screens.length > 0 ? (
                                    screens.filter(item => item.stage === 2).map((item, i) => (
                                        <SocialReachesSingleItem
                                            key={i}
                                            {...item}
                                        />
                                    ))
                                ) : (
                                    <p>სქრინები არ არის ატვირთული</p>
                                )}
                            </div>
                        </TabPanel>
                    </Tb>
                    
                    {/* <div className="card-actions justify-end">
                        {activeStage === 1 && user.stage === 2 ? (
                            <a href={`#socModal_${social}`} className="btn" disabled>დამატება</a>
                        ) : (
                            <a href={`#socModal_${social}`} className="btn">დამატება</a>
                        )}
                    </div> */}
                </div>
            </div>

            {/* <div id={`socModal_${social}`} className="modal">
                <div className="modal-box">
                    <div className='flex items-center justify-between'>
                        <h3 className="font-bold text-lg">{title}</h3>
                        <a href="#" className="btn">X</a>
                    </div>
                    <AddScreenshots social={social} stage={activeStage} />
                </div>
            </div> */}
        </>
    );
};

export default SocialReachesSingle;