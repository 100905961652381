import React from 'react'

export default function ScreenShotField({i, setScreens, removeScreen, field, setError}) {
  const onChange = (e) => {
    let value = e.target.value;
    setError("");
    setScreens((prev) => prev.map((item, index) => {
        if(index === i){
            return {
                ...item,
                views: value ? parseInt(value) : null
            }
        }
        return item;
    }))
  }

  return (
      <div className='h-full'>
          <div className='w-full h-[400px] relative'>
                <div className='h-full bg-gray-300 rounded-md'>
                    <img src={process.env.REACT_APP_EXPRESS_URL+field.image} className='w-full h-full object-cover' alt=''/>
                </div>
                <span className='z-10 bg-white absolute p-2 right-2 top-2 cursor-pointer rounded-md' onClick={() => removeScreen(i, field.image)}>წაშლა</span>
            </div>
            <div className='divider'></div>
            <p className='text-center'>რიჩები</p>
            <input
                value={field.views ? field.views : ""}
                type='number'
                className='w-full h-full border px-2 py-4 rounded-lg'
                onChange={(e) => onChange(e)}
            />
      </div>
  )
}
