import { useDispatch, useSelector } from "react-redux";
import { selectIsAuth } from "./redux/slices/auth";
import { Suspense, useEffect } from "react";
import { fetchAuthMe } from "./redux/thunks/authThunks";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/main/Index";
import Navbar from "./components/Navbar";
import About from "./pages/about/Index";
import Terms from "./pages/terms/Index";
import Participants from "./pages/participants/Index";
import Register from "./pages/register/Index";
import Login from "./pages/login/Index";
import useCurrentUser from "./hooks/useCurrentUser";
import Contact from "./pages/contact/Index";
import Profile from "./pages/profile/Index";
import EmailVerify from "./pages/emailVerification/EmailVerify";
import AdminPanel from "./pages/adminPanel/Index";
import AdminLogin from "./pages/adminPanel/AdminLogin";
import Statistics from "./pages/adminPanel/adminTabs/Statistics";
import MainPage from "./pages/adminPanel/adminTabs/MainPage";
import AboutAdmin from "./pages/adminPanel/adminTabs/About";
import TermsAdmin from "./pages/adminPanel/adminTabs/Terms";
import ParticipantsAdmin from "./pages/adminPanel/adminTabs/ParticipantsAdmin";
import ObjectElem from "./pages/objects/Index";
import ObjectSingle from "./pages/objects/ObjectSingle";
import EditObject from "./pages/objects/EditObject";
import ObjLogin from "./pages/objects/ObjLogin";
import Footer from "./components/Footer";
import ObjRegister from "./pages/objects/ObjRegister";
import ObjectDashboard from "./pages/objectDashboard/Index";
import Archive from "./pages/adminPanel/adminTabs/Archive";
import ResetPassword from "./pages/resetPassword/Index";
import CheckEmail from "./pages/resetPassword/CheckEmail";
import VerifyReset from "./pages/resetPassword/VerifyReset";
import Loader from "./components/Loader";
import ToursAdmin from "./pages/adminPanel/adminTabs/ToursAdmin";
import HiddenParticipants from "./pages/adminPanel/adminTabs/HiddenParticipants";

function App() {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const { status, user } = useCurrentUser();

  useEffect(() => {
    dispatch(fetchAuthMe());
  }, [dispatch]);

  if (status === 'loading') {
    return <Loader />
  }

  return (
    <div className="flex flex-col">
      <Suspense fallback={<Loader />}>
        <Navbar currentUser={user} />
        <div className={`main font-fira-go h-fit`}>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/participants" element={<Participants />} />
            <Route path="/register" element={isAuth ? <Navigate to='/profile' /> : <Register />} />
            <Route path="/login" element={isAuth ? <Navigate to='/profile' /> : <Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/profile" element={(isAuth && user?.role === 'participant') ? <Profile /> : user?.role === 'object' ? <Navigate to={'/object-dashboard'} /> : user?.role === 'admin' ? <Navigate to={'/admin'} /> : <Navigate to='/login' />} />
            <Route path="/email/verify" element={user?.verified ? <Navigate to='/profile' /> : <EmailVerify />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/object" element={<ObjectElem />} />
            <Route path="/objects/:id" element={<ObjectSingle />} />
            <Route path="/object/edit" element={<EditObject />} />
            <Route path="/objlogin" element={!user ? <ObjLogin /> : <Navigate to='/object-dashboard' />} />
            <Route path="/objregister" element={!user ? <ObjRegister /> : <Navigate to='/object-dashboard' />} />
            <Route path="/object-dashboard" element={user?.role === 'object' ? <ObjectDashboard /> : <Navigate to='/' />} />
            <Route path="/admin" element={user?.role === 'admin' ? <AdminPanel /> : <Navigate to='/' />}>
              <Route path="statistics" element={<Statistics />} />
              <Route path="main" element={<MainPage />} />
              <Route path="about" element={<AboutAdmin />} />
              <Route path="terms" element={<TermsAdmin />} />
              <Route path="participants" element={<ParticipantsAdmin />} />
              <Route path="tours" element={<ToursAdmin />} />
              <Route path="archive" element={<Archive />} />
              <Route path="hidden" element={<HiddenParticipants />} />
            </Route>
            <Route path="/:role/reset-password" element={<ResetPassword />} />
            <Route path="/reset-password/check" element={<CheckEmail />} />
            <Route path="/:role/reset-password/change" element={<VerifyReset />} />
          </Routes>
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
