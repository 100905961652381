import React from 'react'
import { Controller } from 'react-hook-form'
import Input from '../../../Forms/formComponents/Input'
export default function ScreenShotField({i, watch, control, errors, removeScreen, disabled}) {

  if (!watch(`screens[${i}]`)) {
      return null;
  }
  
  return (
      <div className='h-full'>
          <div className='w-full h-[400px] relative'>
                <div className='h-full bg-gray-300 rounded-md'>
                    <img src={process.env.REACT_APP_EXPRESS_URL+watch(`screens[${i}].image`)} alt=''/>
                </div>
                <span className='z-10 bg-white absolute p-2 right-2 top-2 cursor-pointer rounded-md' onClick={() => removeScreen(i)}>წაშლა</span>
            </div>
            {(errors.screens && errors.screens[i]) && <p className='text-red-600 mt-2'>{errors.screens[i].image && errors?.screens[i]?.image?.message}</p>}
            <div className='divider'></div>
            {!disabled && (
                <Controller
                    name={`screens[${i}].views`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            type={'number'}
                            label="რიჩები"
                            min='1'
                            aria-invalid={(errors.screens && errors.screens[i] && errors.screens[i].views) && errors?.screens[i].views.message}
                        />
                    )}
                />
            )}
      </div>
  )
}
