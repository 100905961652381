import React, { useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ParticipantsArchive from './archeivePages/ParticipantsArchive';
import ScreensArchive from './archeivePages/ScreensArchive';
import ToursArchive from './archeivePages/ToursArchive';
import axios from "../../../libs/axios"
import toast from 'react-hot-toast';

const Archive = () => {

    const [inpVal, setInpVal] = useState('');
    const [loading, setLoading] = useState(false);

    const archiveAllTheData = async () => {
        setLoading(true)
        if(!inpVal) return toast.error("შეიყვანეთ წელი")
        axios.post('/archive', { date: inpVal })
            .then(res => {
                console.log(res.data);
                window.location.href = "/admin/archive"
                setLoading(false);
            })
            .catch(err => {
                console.error(err)
                setLoading(false);
            })
    }
    const onChange = (e) => {
        setInpVal(e.target.value);
    }

    
    return (
        <div>
            <Tabs selectedTabClassName='tab-active' className='mt-5'>
                <a href='#my_modal' className='btn bg-purple-500 text-white w-full'>დაარქივება</a>
                <div className="divider"></div>
                <TabList className='m-0 flex flex-col sm:flex-row'>
                    <Tab className="tab flex justify-center items-center w-full sm:w-1/3 tab-lg tab-lifted font-fira-go">მონაწილეები</Tab>
                    <Tab className="tab flex justify-center items-center w-full sm:w-1/3 tab-lg tab-lifted font-fira-go">სქრინშოთები</Tab>
                    <Tab className="tab flex justify-center items-center w-full sm:w-1/3 tab-lg tab-lifted font-fira-go">პაკეტები</Tab>
                </TabList>
                <div className="divider"></div>
                <TabPanel>
                    <div className='flex flex-wrap gap-8'>
                        <ParticipantsArchive />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className='flex flex-wrap gap-8'>
                        <ScreensArchive />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className='flex flex-wrap gap-8'>
                        <ToursArchive />
                    </div>
                </TabPanel>
            </Tabs>
            <div className="modal" id='my_modal'>
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="year">შეიყვანეთ წელი</label><br />
                    <input disabled={loading} type='number' min={0} name='year' value={inpVal} onChange={onChange} className='border-2 w-full mt-2 p-1' />
                    <p className='text-center my-2'>დარწმუნებული ხართ რომ გსურთ დაარქივოთ ყველაფერი?</p>
                    <button disabled={loading} className='btn bg-red-500 text-white w-full' onClick={archiveAllTheData}>
                        {loading ? "loading..." : "დაარქივება"}
                    </button>
                    <div className="modal-action">
                        <a disabled={loading} href="#" className="btn" onClick={() => setInpVal('')}>დახურვა</a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Archive