import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import supabase from '../../../../libs/supabase';
import { getAllArchivedParticipants, getAllParticipants } from '../../../../redux/thunks/participants';
import axios from "../../../../libs/axios"
import toast from 'react-hot-toast';
import ExportToExcel from './exports/ExportToXls';
import { getYearOnly } from '../../../../utils/getYearOnly';

// radgan 2023 wlis baza ar gvaqvs da damkveti itxovs, ori baza gvaq da ikac vlinkavt xelit... :(
const PastWinnersIds = [
    'd3e9966c-59f2-4aed-bcfc-9bcd4c86b2ab',
    '0af9a955-208c-4a9e-975e-d9a759a79006',
    'ce19da05-95e0-4234-ac8a-471cebd631c3',
    '8d7b572f-eb26-4758-af3c-7cef9caa5ae6',
    'fc15242e-4932-4afb-97f8-0da92ddc7316',
    '567df4ca-805c-4fc5-9253-90431f99fa83'
];

const ParticipansArchive = () => {
    const [users, setUsers] = useState([]);
    const [selectedYear, setSelectedYear] = useState(0);
    const [years, setYears] = useState([]);
    const dispatch = useDispatch();
    const participants = useSelector((state) => state.participants);

    useEffect(() => {
        axios.get('/archive')
            .then(res => setYears(res.data))
            .catch(err => toast.error('არქივი ვერ მოიძებნა; დაფიქსირდა შეცდომა'))
    }, []);
    
    console.log(participants);
    useEffect(() => {
        const fetchPastYearsParticipants = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select("*")
                .eq('active', true)
                .order("created_at", { ascending: false });

            if (error) {
                console.error('Error fetching winners:', error);
            } else {
                const winners = data.filter(user => PastWinnersIds.includes(user.id));
                const otherUsers = data.filter(user => !PastWinnersIds.includes(user.id));

                winners.sort((a, b) => PastWinnersIds.indexOf(a.id) - PastWinnersIds.indexOf(b.id));

                setUsers([...winners, ...otherUsers]);
            }
        };

        if (selectedYear === 2023) {
            fetchPastYearsParticipants();
        }
    }, [selectedYear]);

    useEffect(() => {
        if (selectedYear === 0) {
            dispatch(getAllParticipants({ hidden: false }));
        } else {
            dispatch(getAllArchivedParticipants({ date: selectedYear.toString() }));
        }
    }, [dispatch, selectedYear]);

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleToggleStage = async (user) => {
        const newStage = user.stage === 1 ? 2 : 1;
        axios.post('/admin/stage', { userId: user._id, stage: newStage })
            .then(res => {
                if (res.status === 200) {
                    toast.success('მომხმარებლის ეტაპი შეიცვალა');
                    dispatch(getAllParticipants({  hidden: false }))
                }
            })
            .catch(err => toast.error(err.response?.data?.message));
    };

    return (
        <div className="container mx-auto my-4 px-7">
            <div className="flex justify-center space-x-4 mb-4">
                <button
                    className={`btn ${selectedYear === 0 ? 'btn-primary' : 'btn-neutral'}`}
                    onClick={() => handleYearChange(0)}
                >
                    ახლა
                </button>
                {years?.map((year, _index) => {
                    if (getYearOnly(year.date) === 2023) return null;
                    return (
                        <button
                            key={_index}
                            className={`btn ${selectedYear === getYearOnly(year.date) ? 'btn-primary' : 'btn-neutral'}`}
                            onClick={() => handleYearChange(getYearOnly(year.date))}
                        >
                            {getYearOnly(year.date)}
                        </button>
                    )
                })}
                <button
                    className={`btn ${selectedYear === 2023 ? 'btn-primary' : 'btn-neutral'}`}
                    onClick={() => handleYearChange(2023)}
                >
                    2023
                </button>
            </div>
            <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {selectedYear === 2023
                    ? (
                        users && users.length > 0 ? users.map((user, i) => (
                            <UserCard
                                key={i}
                                user={user}
                                isWinner={i < PastWinnersIds.length}
                                handleToggleStage={handleToggleStage}
                                isPast={true}
                            />
                        )) : 'იტვირთება...'
                    )
                    : (
                        participants?.data && Array.isArray(participants.data) ? participants.data.map((user, i) => (
                            <UserCard
                                key={i}
                                user={user}
                                handleToggleStage={handleToggleStage}
                                isArchived={true}
                            />
                        )) : 'იტვირთება...'
                    )
                }
            </div>
            <div className='w-full flex-col sm:flex-row flex justify-end mt-8'>
                {(participants.data && selectedYear !== 2023) && <ExportToExcel data={participants.data} />}
                {(users && selectedYear === 2023) && <ExportToExcel data={users} />}
            </div>
        </div>
    );
};

const UserCard = ({ user, isWinner, isPast }) => (
    <div className="flex flex-col sm:flex-row bg-white shadow-md p-4 rounded-lg">
        {isPast ?
            <img src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profiles/${user.avatar_url}.webp`} alt={user.name} className="w-full sm:w-24 h-24 rounded-full object-cover mb-4 sm:mb-0 sm:mr-4" />
            :
            <img src={`${process.env.REACT_APP_EXPRESS_URL}${user.avatar_url}`} alt={user.name} className="w-full sm:w-24 h-24 rounded-full object-cover mb-4 sm:mb-0 sm:mr-4" />
        }
        <div className="flex flex-col justify-between flex-grow">
            <div>
                <h2 className="text-xl font-bold">{user.name} {user.lastname}</h2>
                <p>{user.personalNumber}</p>
                <p>{user.personalnumber}</p>
                <p>პაკეტი: {user?.tour?.id}</p>
                <p>{user.email}</p>
                <p>ტელ: {user.tel}</p>
                <div className="flex space-x-2 mt-2">
                    {isPast ? (
                        <>
                            {user.fb && <a href={user.fb} className="text-blue-600">FB</a>}
                            {user.yt && <a href={user.yt} className="text-red-600">YT</a>}
                            {user.ig && <a href={user.ig} className="text-pink-600">IG</a>}
                            {user.tk && <a href={user.tk} className="text-black">TK</a>}
                        </>
                    ) : (
                        <>
                            {user.soacial?.fb && <a href={user.social?.fb} className="text-blue-600">FB</a>}
                            {user.soacial?.yt && <a href={user.social?.yt} className="text-red-600">YT</a>}
                            {user.soacial?.ig && <a href={user.social?.ig} className="text-pink-600">IG</a>}
                            {user.soacial?.tk && <a href={user.social?.tk} className="text-black">TK</a>}
                        </>
                    )}
                </div>

            </div>
            <div className="flex justify-between items-center mt-4">
                {isWinner && <span className="badge badge-success">Winner</span>}
            </div>
        </div>
    </div>
);

export default ParticipansArchive;
