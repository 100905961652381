import React, { lazy, Suspense, useEffect, useState } from 'react';
import supabase from '../libs/supabase';
const UserCard = lazy(() => import("./Participants/UserCard"))

const ids = [
    'd3e9966c-59f2-4aed-bcfc-9bcd4c86b2ab',
    '0af9a955-208c-4a9e-975e-d9a759a79006',
    'ce19da05-95e0-4234-ac8a-471cebd631c3',
    '8d7b572f-eb26-4758-af3c-7cef9caa5ae6',
    'fc15242e-4932-4afb-97f8-0da92ddc7316',
    '567df4ca-805c-4fc5-9253-90431f99fa83'
];

const Winners = () => {
    const [winners, setWinners] = useState([]);

    useEffect(() => {
        const fetchWinners = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select("*")
                .in("id", ids);

            if (error) {
                console.error('Error fetching winners:', error);
            } else {
                // Sort winners based on the order of ids array
                const sortedWinners = ids.map(id => data.find(winner => winner.id === id)).filter(Boolean);
                setWinners(sortedWinners);
            }
        };

        fetchWinners();
    }, []);

    return (
        <div className='flex flex-col items-center'>
            <h1 className='text-2xl mb-2 text-center'>2023 წლის კამპანიის გამარჯვებულები</h1>
            <div className='grid box-border px-10 md:px-20 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                {winners.map((winner, _index) => (
                    <UserCard
                        avatar_url={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profiles/${winner.avatar_url}.webp`}
                        name={winner.name}
                        lastname={winner.lastname}
                        fb={winner.fb}
                        ig={winner.ig}
                        tk={winner.tk}
                        yt={winner.yt}
                        isWinner={true}
                        place={_index + 1}
                    />
                ))}
            </div>
        </div>
    );
};

export default Winners;
